@import url('https://fonts.googleapis.com/css?family=Oswald:200,300,400,500,600,700');

html,
body {
  font-family: 'Oswald', sans-serif;
  font-weight: 200;
  width: 100%;
  height: 100%;
  margin: 0;
}

html {
  font-size: 62.5% !important;
}

body {
  position: relative;
}

